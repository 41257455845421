import React, { useState } from "react"
import styled from "styled-components"
import { Button } from "./Button"
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import axios from "axios"

const formAPI = "/.netlify/functions/addToEmailList"

const Email = () => {
  const [email, setEmail] = useState("")
  const [submissionState, setSubmissionState] = useState({
    submitted: false,
    success: false,
  })

  const handleChange = e => setEmail(e.target.value)
  const formHandler = email => {
    const data = {
      email: email,
    }

    return axios({
      method: "post",
      url: formAPI,
      data: data,
    })
  }
  const onClick = event => {
    event.preventDefault()

    formHandler(email)
      .then(() => {
        setSubmissionState({
          submitted: true,
          success: true,
        })
        setEmail("")
      })
      .catch(e => {
        setSubmissionState({
          submitted: true,
          success: false,
        })
      })
  }
  const data = useStaticQuery(graphql`
    query MailQuery {
      site {
        siteMetadata {
          title
        }
      }

      allFile(
        filter: {
          ext: { regex: "/(jpg)|(png)|(jpeg)/" }
          name: { in: ["emailBg"] }
        }
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)
  return (
    <div>
      <EmailContainer>
        <EmailImage
          image={data.allFile.edges[0].node.childImageSharp.gatsbyImageData}
          alt=""
        />
        <EmailContent>
          <EmailContentItems>
            <h1>Bleib up-to-date</h1>
            <p>Melde dich jetzt für den kostenlosen Newsletter an!</p>
            <form name="newsletter" action="/" method="POST" netlify="true">
              <FormWrap>
                <label htmlFor="email">
                  <input
                    type="email"
                    placeholder="E-Mail Adresse"
                    id="email"
                    value={email}
                    onChange={handleChange}
                  ></input>
                </label>
                <input type="hidden" name="form-name" value="contact" />
                <Button
                  as="button"
                  round
                  css={`
                    height: 48px;
                  `}
                  onClick={onClick}
                >
                  Sign up
                </Button>
              </FormWrap>
            </form>
            <SuccessMessageBox
              id="infoBox"
              state={submissionState.success}
              style={{
                visibility: submissionState.submitted ? "visible" : "hidden",
              }}
            >
              {submissionState.success
                ? "Danke für deine Anmeldung!"
                : "Bitte überprüfe deine E-Mail Adresse!"}
            </SuccessMessageBox>
          </EmailContentItems>
        </EmailContent>
        {/* <EmailContent>
          <h1>Bleib up-to-date</h1>
          <p>Melde dich jetzt für den kostenlosen Newsletter an!</p>
          <form name="newsletter" netlify>
            <FormWrap>
              <label htmlFor="email">
                <input
                  type="email"
                  placeholder="E-Mail Adresse"
                  id="email"
                ></input>
              </label>
              <input type="hidden" name="form-name" value="contact" />
              <Button
                as="button"
                type="submit"
                round
                css={`
                  height: 48px;
                `}
              >
                Sign up
              </Button>
            </FormWrap>
          </form>
        </EmailContent> */}
      </EmailContainer>
    </div>
  )
}

export default Email

const EmailContainer = styled.div`
  height: 450px;
  width: 100%;
  //padding: 5rem calc((100vw - 1300px) / 2);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  :before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.6) 100%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
  }
`

/* const EmailImageWrapper = styled.div`
  height: 450px;
` */
const EmailImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  overflow: hidden;
`
const EmailContentItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  color: #fff;

  font-weight: bold;
`

const EmailContent = styled.div`
  z-index: 3;
  position: absolute;

  h1 {
    text-align: center;
    margin-bottom: 1rem;
    font-size: clamp(1rem, 5vw, 3rem);
    padding: 0 1rem;
  }
  p {
    text-align: center;
    font-size: clamp(1rem, 2.5vw, 1.5rem);
    padding: 0 1rem;
    margin-bottom: 2rem;
  }

  form {
    z-index: 10;
  }
`

const SuccessMessageBox = styled.div`
  padding: 0.5rem 2rem;
  margin-top: 0.5rem;
  width: 100%;
  background-color: ${({ state }) =>
    state ? "rgba(37, 187, 51, 0.5)" : "rgba(187, 33, 36, 0.5)"};
  border-radius: 50px;
`
const FormWrap = styled.div`
  input {
    padding: 1rem 1.5rem;
    outline: none;
    width: 350px;
    height: 48px;
    border-radius: 50px;
    border: none;
    margin-right: 1rem;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 1rem;
    width: 100%;
    margin-right: 0;
  }
`
