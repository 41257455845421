import React from "react"
import styled from "styled-components"

import { GiPeaks } from "@react-icons/all-files/gi/GiPeaks"
import { FaThinkPeaks } from "@react-icons/all-files/fa/FaThinkPeaks"
import { FaSkiingNordic } from "@react-icons/all-files/fa/FaSkiingNordic"
import { FaHiking } from "@react-icons/all-files/fa/FaHiking"
const Stats = () => {
  const StatsData = [
    {
      icon: (
        <FaHiking
          css={`
            color: #047bf1;
          `}
        />
      ),
      title: "Gipfel-Touren",
      number: "49",
    },
    {
      icon: (
        <GiPeaks
          css={`
            color: #f3a82e;
          `}
        />
      ),
      title: "Hochtouren",
      number: "12",
    },
    {
      icon: (
        <FaSkiingNordic
          css={`
            color: #f34f2e;
          `}
        />
      ),
      title: "Skitouren",
      number: "21",
    },
    {
      icon: (
        <FaThinkPeaks
          css={`
            color: #3af456;
          `}
        />
      ),
      title: "Klettersteige",
      number: "18",
    },
  ]

  return (
    <StatsContainer>
      <Heading>Überblick</Heading>
      <Wrapper>
        {StatsData.map((item, index) => {
          return (
            <StatsBox key={index}>
              <Icon>{item.icon}</Icon>
              <StatsWrapper>
                <ActivityCounter>{item.number}</ActivityCounter>
                <Title>{item.title}</Title>
              </StatsWrapper>
            </StatsBox>
          )
        })}
      </Wrapper>
    </StatsContainer>
  )
}

export default Stats

const StatsContainer = styled.div`
  width: 100%;
  background: #fff;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem calc((100vw - 1300px) / 2);
`

const Heading = styled.h1`
  text-align: start;
  font-size: clamp(1.5rem, 5vw, 2rem);

  margin-bottom: 3rem;
  padding: 0 2rem;
`

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`
const StatsBox = styled.div`
  height: 100%;
  width: 100%;
  padding: 2rem;
`

const Icon = styled.div`
  font-size: 3rem;
  margin-bottom: 1rem;
`

const Title = styled.p`
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
`

const ActivityCounter = styled.p`
  font-size: 3rem;
`

const StatsWrapper = styled.div`
  display: flex;
  align-items: baseline;
`
