import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
//import { IoMdCheckmarkCircleOutline } from "react-icons/io"
//import { FaRegLightbulb } from "react-icons/fa"
import { useStaticQuery } from "gatsby"

import { graphql } from "gatsby"

const Testimonials = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allFile(
        filter: {
          ext: { regex: "/(jpg)|(png)|(jpeg)/" }
          name: { in: ["1_jonny", "2_alpinchaoten"] }
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(transformOptions: { fit: INSIDE })
            }
          }
        }
      }
    }
  `)

  return (
    <TestimonialsContainer>
      <TopLine>Gipfeltouren, Hochtouren, Klettersteige &amp; Skitouren</TopLine>
      <Description>Seilschaft fürs Leben</Description>
      <ContentWrapper>
        <ColumnOne>
          <Testimonial>
            {/*      <IoMdCheckmarkCircleOutline
              css={`
                color: #3fffa8;
                font-size: 2rem;
                margin-bottom: 1rem;
              `}
            /> */}
            <h3>Johannes</h3>
            <p>
              Als IT Berater bin ich ständig damit konfrontiert, Anforderungen
              in realisierbare Projekte zu formen, umzusetzen und schlussendlich
              die Einführung der Lösung in den Fachabteilung zu begleiten. Eine
              alpine Unternehmung folgt der selben Vorgehensweise: Ausgehend von
              der Idee, einen Gipfel zu besteigen, über die Planung der Anreise
              und Route bis hin zur Tour finden sich viele Parallelen zu meinen
              beruflichen Aufgaben. So sind die Ausflüge in die Alpen nich nur
              ein fantastischer körperlicher Ausgleich sondern auch eine
              perfekte mentale Ergänzung.
            </p>
          </Testimonial>
          <Testimonial>
            {/* <FaRegLightbulb
              css={`
                color: #f9b19b;
                font-size: 2rem;
                margin-bottom: 1rem;
              `}
            /> */}
            <h3>Die Alpinchaoten</h3>
            <p>
              Während eines Spontan-Trips ins "Gseis" (Nationalpark Gesäuse)
              wurde die Lifestyle-Marke "Alpinchaoten" gegründet. Seither
              durften wir viele Abenteuer erleben und den Moment einer
              gelungenen Tour gemeinsam genießen.
            </p>
          </Testimonial>
        </ColumnOne>
        <ColumnTwo>
          {data.allFile.edges.map((image, key) => (
            <Images
              key={key}
              image={image.node.childImageSharp.gatsbyImageData}
              alt={image.node.name}
            />
          ))}
        </ColumnTwo>
      </ContentWrapper>
    </TestimonialsContainer>
  )
}

export default Testimonials

const TestimonialsContainer = styled.div`
  width: 100%;
  background: #fcfcfc;
  color: #000;
  padding: 5rem calc((100vw - 1300px) / 2);
  height: 100%;
`

const TopLine = styled.div`
  color: #077bf1;
  font-size: 1rem;
  padding-left: 2rem;
  margin-bottom: 0.75rem;
`

const Description = styled.p`
  text-align: start;
  padding-left: 2rem;
  margin-bottom: 4rem;
  font-size: clamp(1.5rem, 5vw, 2rem);
  font-weight: bold;
`

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 2rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const ColumnOne = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
`

const Testimonial = styled.div`
  padding-top: 1rem;
  padding-right: 2rem;

  h3 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-style: italic;
  }
  p {
    color: #3b3b3b;
    font-size: 18px;
  }
`

const ColumnTwo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 2rem;
  grid-gap: 10px;
  max-height: 600px;

  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`
const Images = styled(GatsbyImage)`
  border-radius: 10px;

  height: 100%;
  min-height: 500px;
  width: 100%;
`
